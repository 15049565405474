// import 'postscribe'
// import '../../node_modules/cookies-enabler'

COOKIES_ENABLER.init({
    scriptClass: 'ce-script',
    iframeClass: 'ce-iframe',

    acceptClass: 'ce-accept',
    dismissClass: 'ce-dismiss',
    disableClass: 'ce-disable',

    bannerClass: 'ce-banner',
    bannerHTML:
        '<p>Ce site utilise des cookies pour enrichir ses fonctionalités et à des fins de mesure d\'audience. '
            +'<a href="#" class="ce-accept">'
                +'Accepter les Cookies'
            +'</a>'
        +'</p>',

    eventScroll: false,
    scrollOffset: 200,

    clickOutside: false,

    cookieName: 'ce-cookie',
    cookieDuration: '365',
    wildcardDomain: false,

    iframesPlaceholder: true,
    iframesPlaceholderHTML:
        '<p>To view this content you need to'
            +'<a href="#" class="ce-accept">Enable Cookies</a>'
        +'</p>',
    iframesPlaceholderClass: 'ce-iframe-placeholder',

    // Callbacks
    onEnable: function(){
         
    },
    onDismiss: '',
    onDisable: ''
    
})